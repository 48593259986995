import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Container,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState, ChangeEvent } from "react";
import {
  useGetIdentity,
  useNotify,
  usePermissions,
  useTranslate,
} from "react-admin";
import { APIURL } from "../../global/global";
import OrganizationAutocomplete from "./OrganizationAutocomplete";
import { CGUItem, ComingSoonItem, Item, RolesItem } from "./Items";

interface User {
  first_name: string;
  last_name: string;
  password: string | null;
  email: string;
  roles: string[];
  organization: {
    id: number;
    name: string;
  };
}

const initialErrors = {
  email: false,
  first_name: false,
  last_name: false,
  password: false,
  roles: false,
  organization: false,
};

export const Profile: React.FC = () => {
  const notify = useNotify();
  const translate = useTranslate();
  const [edit, setEdit] = useState(false);
  const [userState, setUserState] = useState<User | null>(null);
  const { data, isPending, error } = useGetIdentity();
  const { permissions } = usePermissions();
  const isAdmin = permissions?.includes("admin");
  const [errors, setErrors] = useState(initialErrors);
  const hasErrors = Object.values(errors).some((error) => error);

  useEffect(() => {
    if (data) {
      setUserState({
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        roles: data.roles,
        organization: data.organization,
        password: null,
      });
    }
  }, [data]);

  const onSave = async () => {
    try {
      const { id, email, first_name, last_name, organization, roles, cgu_accepted_at } =
        await axios
          .post(`${APIURL}/users/${data.id}`, {
            ...userState,
            organization_id: userState.organization.id,
            organization: undefined,
          })
          .then(({ data }) => data);

      localStorage.setItem(
        "me",
        JSON.stringify({ id, email, first_name, last_name, cgu_accepted_at })
      );
      localStorage.setItem("roles", JSON.stringify(roles));
      localStorage.setItem("organization", JSON.stringify(organization));
      notify(translate("profile.update_success"), { type: "success" });
      window.location.reload();
    } catch (e) {
      notify("ra.notification.http_error", { type: "error" });
    }
  };

  const onCancel = () => {
    setEdit(false);
    setUserState({
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      roles: data.roles,
      organization: data.organization,
      password: null,
    });
  };

  const validateTextItemValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { type, name, value, required } = event.target;
    const e: { [key: string]: boolean } = {};
    if (type === "email") {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      e[name] = !emailRegex.test(value);
    } else if (required) {
      e[name] = !value;
    }
    setErrors((prevErrors) => ({ ...prevErrors, ...e }));
  };

  const handleTextItemChange = (event: ChangeEvent<HTMLInputElement>) => {
    validateTextItemValue(event);
    setUserState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  if (isPending || error || !userState) return null;

  return (
    <Container maxWidth='md'>
      <Stack alignItems='flex-end' p={2} gap={4}>
        <Stack
          direction='row'
          justifyContent='space-between'
          width='100%'
          alignItems='center'
        >
          <Box />
          <Typography
            // fontWeight='bold'
            variant='h5'
            textAlign='center'
            width='100%'
          >
            {translate("profile.title")}
          </Typography>
          {!edit && (
            <IconButton onClick={() => setEdit(true)}>
              <EditIcon />
            </IconButton>
          )}
        </Stack>

        <Stack
          p={4}
          gap={edit ? 2 : 6}
          width='100%'
          component={Paper}
          variant='outlined'
        >
          <Item
            name='email'
            title={translate("profile.email")}
            value={userState.email}
            onChange={handleTextItemChange}
            edit={edit}
            disabled={!isAdmin}
            required
            type='email'
            error={errors.email}
          />

          <Item
            name='first_name'
            title={translate("profile.first_name")}
            value={userState.first_name}
            onChange={handleTextItemChange}
            edit={edit}
            required
            error={errors.first_name}
          />

          <Item
            name='last_name'
            title={translate("profile.last_name")}
            value={userState.last_name}
            onChange={handleTextItemChange}
            edit={edit}
            required
            error={errors.last_name}
          />

          <Item
            name='organization'
            title={translate("profile.organization")}
            value={userState.organization}
            valueKey='name'
            edit={edit}
            required
            disabled={!isAdmin}
            field={OrganizationAutocomplete}
            onChange={(event, value) => {
              setErrors((prevErrors) => ({
                ...prevErrors,
                organization: !value,
              }));
              setUserState((prevState) => ({
                ...prevState,
                organization: { id: value?.id, name: value?.name },
              }));
            }}
            error={errors.organization}
          />

          <ComingSoonItem
            name='sites'
            title={translate("profile.sites")}
            edit={edit}
            disabled
          />

          <RolesItem
            name='roles'
            title={translate("profile.roles")}
            value={data.roles}
            edit={edit}
            required
            disabled={!isAdmin}
            onChange={(roles) => {
              setErrors((prevErrors) => ({
                ...prevErrors,
                roles: roles?.length === 0,
              }));
              setUserState((prevState) => ({
                ...prevState,
                roles,
              }));
            }}
            error={errors.roles}
          />

          {!edit && (
            <CGUItem
              title={translate('profile.cgu_shortcut')}
              id={data.id}
              cgu_accepted_at={data.cgu_accepted_at}
            />
          )}

          {edit && (
            <Item
              name='password'
              title={translate("profile.password")}
              value={userState.password}
              onChange={handleTextItemChange}
              edit={edit}
              type='password'
            />
          )}
        </Stack>

        {edit && (
          <Stack direction='row' gap={2}>
            <Button variant='text' color='inherit' onClick={onCancel}>
              {translate("misc.cancel")}
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={onSave}
              disabled={hasErrors}
            >
              {translate("profile.save")}
            </Button>
          </Stack>
        )}
      </Stack>
    </Container>
  );
};
