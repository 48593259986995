import {FileField, FileInput, FormTab, ImageField, ImageInput,} from "react-admin";

export const FilesFormTab = (props) => {
  return (
    <FormTab {...props} label='Fichiers'>
      {/* Build */}
      <FileInput
        source='build'
        label='Build'
        placeholder={<p>Déposer le build (en .zip) ici</p>}
        multiple={false}
      >
        <>
          <FileField source='public_download_link' title='title' />
          <FileField source='src' title='title' />
        </>
      </FileInput>

      {/* Images to upload (with File format) */}
      <ImageInput
        source='images'
        label='Images'
        placeholder={<p>Déposer les images ici</p>}
        multiple={true}
        helperText='Dimension : 720x576'
      >
        <>
          <ImageField source='public_display_link' title='id' />
          <ImageField source='src' title='id' />
        </>
      </ImageInput>
    </FormTab>
  );
};
