import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Container,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  AutocompleteArrayInput,
  ReferenceArrayInput,
  required,
  SimpleForm,
  TextInput,
  usePermissions,
  useRecordContext,
  useRedirect,
  useTranslate,
} from "react-admin";
import CustomSimpleToolbar from "../Layout/CustomSimpleToolbar";
import OptionItem from "./OptionItem";
import { NameField } from "../Experiences/Form/ExperienceForm";
import AutoCreateTag from "../Tags/AutoCreateTag";
import ImageInput from "./IconInput";

const Icon = () => {
  return <ImageInput source={"logo"} />;
};

const Name = () => {
  const translate = useTranslate();
  const { permissions } = usePermissions();
  const isAdmin = permissions?.includes("admin");

  return (
    <TextInput
      placeholder={translate("organizations.name")}
      source='name'
      label={false}
      validate={[required()]}
      helperText={false}
      readOnly={!isAdmin}
    />
  );
};

const Options = () => {
  const translate = useTranslate();
  const { permissions } = usePermissions();
  const isAdmin = permissions?.includes("admin");

  return (
    <Stack gap={3}>
      <OptionItem title={translate("tags.categories.index")}>
        <ReferenceArrayInput
          source={"category_ids"}
          reference={"tags"}
          perPage={1000}
          sort={{ field: "name", order: "ASC" }}
          filter={{ type: "organizationCategory" }}
          fullWidth
          optionText={<NameField />}
          optionValue={"name"}
          readOnly={!isAdmin}
        >
          <AutocompleteArrayInput
            label={false}
            multiple
            inputText={(option) => option?.name}
            matchSuggestion={(filterValue, suggestion) =>
              suggestion?.name
                .toLowerCase()
                .includes(filterValue?.toLowerCase())
            }
            noOptionsText={translate("misc.no_data")}
            disableCloseOnSelect
            blurOnSelect={false}
            create={<AutoCreateTag type={"organizationCategory"} />}
            allowCreate
            createText={translate("tags.categories.create")}
            helperText={false}
            readOnly={!isAdmin}
          />
        </ReferenceArrayInput>
      </OptionItem>
      <OptionItem title={translate("misc.email_domain")}>
        <Stack direction='row' gap={1} alignItems={"center"} ml={-4}>
          <AlternateEmailIcon color='primary' />
          <TextInput
            source='email_domain'
            label={false}
            helperText={false}
            sx={{ m: 0 }}
            fullWidth
            readOnly={!isAdmin}
          />
        </Stack>
      </OptionItem>
      <OptionItem title={translate("organizations.experiences.images.default")}>
        <ImageInput source='default_experience_image' />
      </OptionItem>
    </Stack>
  );
};

const Title = () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const record = useRecordContext();
  return (
    <Stack
      direction={"row"}
      gap={2}
      alignItems={"center"}
      justifyContent={"space-between"}
      my={2}
      width={"100%"}
    >
      <Box />
      <Typography variant='h5' textAlign='center'>
        {translate("organizations.singular_name")}
      </Typography>
      {record ? (
        <IconButton
          onClick={() => redirect("show", "organizations", record.id)}
        >
          <VisibilityIcon />
        </IconButton>
      ) : (
        <Box />
      )}
    </Stack>
  );
};

const OrganizationForm = () => {
  const translate = useTranslate();

  const validateOrganizationForm = (values) => {
    const errors: any = {};
    if (!values.name) {
      errors.name = translate("ra.validation.required");
    }
    return errors;
  };

  return (
    <Container maxWidth={"lg"}>
      <SimpleForm
        toolbar={<CustomSimpleToolbar />}
        validate={validateOrganizationForm}
      >
        <Title />
        <Stack px={8} py={4} gap={8} component={Paper} width={"100%"}>
          <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            gap={4}
          >
            <Stack alignItems='end' position='relative'>
              <Icon />
              <Typography position="absolute" bottom={-5} whiteSpace='nowrap' variant="caption" color="grey" sx={{transform: 'translateY(100%)'}}>{translate('misc.logo_format')}</Typography>
            </Stack>
            <Name />
          </Stack>
          <Options />
        </Stack>
      </SimpleForm>
    </Container>
  );
};

export default OrganizationForm;
