import { Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormDataConsumer, useInput, useNotify, useTranslate } from "react-admin";
import randomColor from "randomcolor";
import { extractColors } from "extract-colors";
import dataProvider from "../../../data-providers/data-provider";

interface ColorPickerProps {
  source: string;
  label: string;
  autoSetColor: boolean;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ source, label, autoSetColor = false }) => {
  const notify = useNotify();
  const translate = useTranslate();

  const [thumbnail, setThumbnail] = useState('');
  const { id, field, fieldState } = useInput({ source });

  const [colorIndex, setColorIndex] = useState(0);
  const [existingColors, setExistingColors] = useState(null);
  const [validColors, setValidColors] = useState<string[]>([]);

  const isRealColor = (hexColor: string): boolean => {
    hexColor = hexColor.startsWith('#') ? hexColor.slice(1) : hexColor;

    const [r, g, b] = [0, 2, 4].map(offset => parseInt(hexColor.slice(offset, offset + 2), 16));
    const saturation = Math.max(r, g, b) - Math.min(r, g, b);
    const brightness = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    return saturation > 10 && brightness > 30;
  };

  const getRandomColor = (): string => {
    let color: string;
    do {
      color = randomColor({
        luminosity: "dark",
        format: "hex",
        hue: "random",
        alpha: 1,
      });
    } while (!isRealColor(color));

    return color;
  };

  const extractValidColorsFromThumbnail = async (thumbnail: string) => {
    try {
      const extractedColors = await extractColors(thumbnail);

      const filteredColors = extractedColors.map(c => c.hex).filter(hex => isRealColor(hex) && !existingColors.includes(hex));

      if (filteredColors.length > 0) {
        field.onChange(filteredColors[0]);
        setValidColors(filteredColors);
        setColorIndex(1);
      } else {
        notify("misc.no_valid_color", { type: "error" });
        console.log('No valid color found in thumbnail');
      }
    } catch {
      notify("misc.no_valid_color", { type: "error" });
      console.log("No color found in thumbnail");
    }
  };

  const handleGenerateColor = () => {
    if (validColors.length > 0) {
      field.onChange(validColors[colorIndex]);
      setColorIndex((colorIndex + 1) % validColors.length);
    } else {
      extractValidColorsFromThumbnail(thumbnail);
    }
  };

  const handleFormDataChange = (formData) => {
    const thumbnailProps = formData?.images?.[0];
    const currentThumbnail = thumbnailProps?.src || thumbnailProps?.public_display_link;

    setThumbnail(prevThumbnail => {
      if (currentThumbnail === null || currentThumbnail === undefined) {
        if (!field.value) {
          console.log('No thumbnail so random color');
          field.onChange(getRandomColor());
        }
        return prevThumbnail;
      }

      if (currentThumbnail !== prevThumbnail) {
        if (autoSetColor) {
          extractValidColorsFromThumbnail(currentThumbnail);
        }
        setValidColors([]);
        return currentThumbnail;
      }

      return prevThumbnail;
    });
  };

  const fetchExistingColors = async () => {
    try {
      const { data } = await dataProvider.getList("experiences");
      setExistingColors(data.map((exp) => exp.color));
      console.log("Existing colors fetched");
    } catch (err) {
      console.error("Error while fetching experiences colors ", err);
    }
  }

  useEffect(() => {
    if (!existingColors) {
      fetchExistingColors();
    }
  }, [existingColors]);

  return (
    <>
      <TextField
        {...field}
        id={id}
        label={label}
        sx={{ width: "6em" }}
        type='color'
        value={field.value || ''}
      />

      <Button
        color="primary"
        onClick={handleGenerateColor}
        size="small"
        disabled={!thumbnail || !existingColors}
      >
        {translate('misc.use_color')}
      </Button>

      <FormDataConsumer>
        {({ formData }) => {
          handleFormDataChange(formData);
          return null;
        }}
      </FormDataConsumer>
    </>
  );
};

export default ColorPicker;
